var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "treeselect",
        {
          attrs: {
            placeholder: _vm.$t("Select or input custom category"),
            options: _vm.options,
            "disable-branch-nodes": false,
            normalizer: _vm.normalizer,
            beforeClearAll: _vm.onClearText,
            noOptionsText: _vm.$t("No options available.")
          },
          on: { select: _vm.onSelect },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _vm.allowCreate
            ? _c(
                "div",
                {
                  staticClass: "ml-sm mt-xs",
                  attrs: { slot: "after-list" },
                  slot: "after-list"
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus-circle" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.openCreateCatForm = true
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Add Root Category")))]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("Add Root Category"),
            "ok-text": _vm.$t("Create"),
            "cancel-text": _vm.$t("Cancel")
          },
          on: { ok: _vm.handleAddNode },
          model: {
            value: _vm.openCreateCatForm,
            callback: function($$v) {
              _vm.openCreateCatForm = $$v
            },
            expression: "openCreateCatForm"
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: { "label-col": { span: 6 }, "wrapper-col": { span: 16 } }
            },
            [
              _c(
                "a-form-item",
                {
                  staticClass: "mb-none",
                  attrs: { label: _vm.$t("Category name") }
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.newItem.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newItem, "name", $$v)
                      },
                      expression: "newItem.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }