//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import the component
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  // register the component
  components: { Treeselect },
  props: {
    category: Object,
    pageId: Number,
    allowCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // define the default value
      value: null,
      // define options
      options: [
        // {
        //   id: "a",
        //   label: "a",
        //   children: [
        //     {
        //       id: "aa",
        //       label: "aa"
        //     },
        //     {
        //       id: "ab",
        //       label: "ab"
        //     }
        //   ]
        // }
      ],
      normalizer(node) {
        return {
          label: node.name
        };
      },
      loading: false,
      openCreateCatForm: false,
      newItem: {
        name: '',
        isLeaf: true,
        parent_id: null
      }
    };
  },
  watch: {
    category(data) {
      this.value = data.id || null;
    },
    originCategories: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.options = newVal;
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('category', ['originCategories']),
    ...mapGetters('page', ['pageById'])
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchCategories(this.pageId);
      this.value = this.category?.id || null;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('category', ['fetchCategories']),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelect(node, instanceId) {
      this.$emit('select', node);
    },
    onClearText() {
      this.onSelect(null);
      return true;
    },
    async handleAddNode() {
      try {
        if (this.options?.length >= 50) {
          throw new Error('Must be 50 item maximum!');
        }
        const result = await this.$s.api.page.createCategory(this.pageId, this.newItem);
        await this.fetchCategories(this.pageId); // Reload category store
        this.onSelect(result);
        this.newItem.name = '';
        this.$notification.success({
          message: 'Successful'
        });
      } catch (error) {
        this.$notification.error({
          message: error.message
        });
        return;
      } finally {
        this.openCreateCatForm = false;
      }
    }
  }
};
